<template>
  <v-container fill-height class="pa-0">
    <v-container>
      <v-card
        class="d-flex flex-column text-center mx-auto my-auto align-center justify-center"
        flat
        color="transparent"
        :height="$vuetify.breakpoint.mdAndUp ? '60vh' : '70vh'"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-card-title class="justify-center font-weight-bold title">
          잠시만 기다려주세요.
        </v-card-title>
        <v-card-subtitle>
          보안을 위해 브라우저를 확인 중입니다
        </v-card-subtitle>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
import API from "@/API/auth";
/* eslint-disable no-unused-vars */

export default {
  data() {
    return {
      token: "",
    };
  },
  async mounted() {
    const naverState = this.$route.query.state;
    const state = window.localStorage.getItem("naverState");

    window.localStorage.removeItem("naverState");

    if (naverState !== state) {
      alert("잘못된 방법으로 접근하셨습니다. 로그인 페이지로 이동합니다.");
      this.$router.push({ name: "Login" });
      return;
    }

    const naverAuthCode = this.$route.query.code;
    if (naverAuthCode) {
      API.signInWithNaver(naverAuthCode, this.$route.query.keepSignIn);
    } else {
      alert("잘못된 방법으로 접근하셨습니다. 로그인 페이지로 이동합니다.");
      this.$router.push({ name: "Login" });
    }
  },
  methods: {},
};
</script>
